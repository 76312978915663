import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import * as dat from 'lil-gui'

const getRandom = (min, max) => {
    const out = ((Math.random() * (max - min)) + min) * (Math.round(Math.random())*2)-1
    return out
}

const randomDirection = () => {
    const out = new THREE.Vector3((Math.random() - 0.5), (Math.random() - 0.5), (Math.random() - 0.5))

    out.normalize()

    return out
}

console.log(randomDirection());

/**
 * Base
 */
// Debug
//const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('textures/matcaps/8.png')

// Materials
const matcapMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

const sphereMaterial = new THREE.MeshStandardMaterial()
sphereMaterial.color.set(0x278EA5)
sphereMaterial.roughness = 0.5
sphereMaterial.metalness = 0.1

const ambientMaterial0 = new THREE.MeshStandardMaterial()
ambientMaterial0.color.set(0x21E6C1)
ambientMaterial0.roughness = 0.5
ambientMaterial0.metalness = 0.1

const ambientMaterial1 = new THREE.MeshStandardMaterial()
ambientMaterial1.color.set(0x1F4287)
ambientMaterial1.roughness = 0.5
ambientMaterial1.metalness = 0.1

const ambientMaterial2 = new THREE.MeshStandardMaterial()
ambientMaterial2.color.set(0x049ef4)
ambientMaterial2.roughness = 0.5
ambientMaterial2.metalness = 0.1

const ambientMaterials = [ ambientMaterial0, ambientMaterial1, ambientMaterial2]

const ringMaterial = new THREE.MeshStandardMaterial()
ringMaterial.color.set(0xe6c613)
ringMaterial.roughness = 0.1
ringMaterial.metalness = 0.5


// Objects

const parameters = new Float32Array( [
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75),
    getRandom(0.5, 0.75)
 ] )

const scope = 3



const sphereGroup = new THREE.Group()
const torusGroup = new THREE.Group()

const pos1 = new THREE.Vector3(0, 0, 0)
const pos2 = pos1.clone().add(new THREE.Vector3(-scope, 0, 0))
const pos3 = pos1.clone().add(new THREE.Vector3(scope, 0, 0))

const pos4 = pos1.clone().add(new THREE.Vector3(scope/2, scope/2, scope/2))
const pos5 = pos1.clone().add(new THREE.Vector3(-scope/2, scope/2, scope/2))
const pos6 = pos1.clone().add(new THREE.Vector3(scope/2, scope/2, -scope/2))
const pos7 = pos1.clone().add(new THREE.Vector3(-scope/2, scope/2, -scope/2))

const pos8 = pos1.clone().add(new THREE.Vector3(scope/2, -scope/2, scope/2))
const pos9 = pos1.clone().add(new THREE.Vector3(-scope/2, -scope/2, scope/2))
const pos10 = pos1.clone().add(new THREE.Vector3(scope/2, -scope/2, -scope/2))
const pos11 = pos1.clone().add(new THREE.Vector3(-scope/2, -scope/2, -scope/2))

const sphereGeometry = new THREE.SphereGeometry(0.5, 32, 32)
const torusGeometry = new THREE.TorusGeometry(0.6, 0.015, 16, 64)

const sphere1 = new THREE.Mesh(sphereGeometry, sphereMaterial)

const torus1 = new THREE.Mesh(torusGeometry, ringMaterial)
torus1.userData = {speed:getRandom(0.5, 1)}

const sphere2 = new THREE.Mesh(sphereGeometry, sphereMaterial)
sphere2.position.copy(pos2)

const torus2 = new THREE.Mesh(torusGeometry, ringMaterial)
torus2.position.copy(pos2)

const sphere3 = new THREE.Mesh(sphereGeometry, sphereMaterial)
sphere3.position.copy(pos3)

const torus3 = new THREE.Mesh(torusGeometry, ringMaterial)
torus3.position.copy(pos3)



const sphere4 = new THREE.Mesh(sphereGeometry, sphereMaterial)
sphere4.position.copy(pos4)

const torus4 = new THREE.Mesh(torusGeometry, ringMaterial)
torus4.position.copy(pos4)

const sphere5 = new THREE.Mesh(sphereGeometry, sphereMaterial)
sphere5.position.copy(pos5)

const torus5 = new THREE.Mesh(torusGeometry, ringMaterial)
torus5.position.copy(pos5)

const sphere6 = new THREE.Mesh(sphereGeometry, sphereMaterial)
sphere6.position.copy(pos6)

const torus6 = new THREE.Mesh(torusGeometry, ringMaterial)
torus6.position.copy(pos6)

const sphere7 = new THREE.Mesh(sphereGeometry, sphereMaterial)
sphere7.position.copy(pos7)

const torus7 = new THREE.Mesh(torusGeometry, ringMaterial)
torus7.position.copy(pos7)



const sphere8 = new THREE.Mesh(sphereGeometry, sphereMaterial)
sphere8.position.copy(pos8)

const torus8 = new THREE.Mesh(torusGeometry, ringMaterial)
torus8.position.copy(pos8)

const sphere9 = new THREE.Mesh(sphereGeometry, sphereMaterial)
sphere9.position.copy(pos9)

const torus9 = new THREE.Mesh(torusGeometry, ringMaterial)
torus9.position.copy(pos9)

const sphere10 = new THREE.Mesh(sphereGeometry, sphereMaterial)
sphere10.position.copy(pos10)

const torus10 = new THREE.Mesh(torusGeometry, ringMaterial)
torus10.position.copy(pos10)

const sphere11 = new THREE.Mesh(sphereGeometry, sphereMaterial)
sphere11.position.copy(pos11)

const torus11 = new THREE.Mesh(torusGeometry, ringMaterial)
torus11.position.copy(pos11)

const bigTorus1 = new THREE.Mesh(new THREE.TorusGeometry(15, 0.15, 16, 96), sphereMaterial)
const bigTorus2 = new THREE.Mesh(new THREE.TorusGeometry(14, 0.15, 16, 96), sphereMaterial)
const bigTorus3 = new THREE.Mesh(new THREE.TorusGeometry(14.5, 0.15, 16, 96), sphereMaterial)
bigTorus3.rotation.y = Math.PI/2


sphereGroup.add(sphere1, sphere2, sphere3, sphere4, sphere5, sphere6, sphere7, sphere8, sphere9, sphere10, sphere11)
torusGroup.add(torus1, torus2, torus3, torus4, torus5, torus6, torus7, torus8, torus9, torus10, torus11)

scene.add(sphereGroup, torusGroup, bigTorus1, bigTorus2, bigTorus3)

// Ambient Spheres
const ambientSphereGeometry = new THREE.SphereGeometry(1, 32, 32)
const ambientTorusGeometry = new THREE.TorusGeometry(1, 0.3, 16, 32)
const ambientConeGeometry = new THREE.ConeGeometry(1, 1.5, 4, 20)
const ambientBoxGeometry = new THREE.BoxGeometry(1)

const ambientGeometries = [
    ambientSphereGeometry, 
    ambientTorusGeometry, 
    ambientConeGeometry,
    ambientBoxGeometry
]

const ambientGroup = new THREE.Group()
const ambientArray = [99]

for(let i = 0; i < 100; i++)
{
    const ambientShape = new THREE.Mesh(ambientGeometries[i%4], ambientMaterials[i%3])
    const dir = randomDirection()
    const mag = ((Math.random() * 0.8) + 0.2) * 15
    ambientShape.position.x = dir.x * mag
    ambientShape.position.y = dir.y * mag
    ambientShape.position.z = dir.z * mag
    ambientShape.rotation.x = Math.random() * Math.PI
    ambientShape.rotation.y = Math.random() * Math.PI
    const scale = ((Math.random() * 0.5) + 0.5) * 0.075
    ambientShape.scale.set(scale, scale, scale)

    ambientArray.push(ambientShape)
    ambientGroup.add(ambientShape)
    scene.add(ambientGroup)
}
// Lights

const ambient = new THREE.AmbientLight(0xffffff, 0.75)

const dirLightGroup = new THREE.Group()

const point = new THREE.DirectionalLight(0xff4444, 1)
point.position.set(-2, 0.5, 1)

const point2 = new THREE.DirectionalLight(0x4444aa, 1)
point2.position.set(2, -1, -4)

dirLightGroup.add(point, point2)

scene.add(ambient, dirLightGroup)

// Axes Helper
//const axesHelper = new THREE.AxesHelper(2)
//scene.add(axesHelper)

/**
 * Fonts
 */
const fontLoader = new FontLoader()

/* fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Material
        const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

        // Text
        const textGeometry = new TextGeometry(
            'Hi there\nIt\'s me',
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        textGeometry.center()

        const text = new THREE.Mesh(textGeometry, material)
        scene.add(text)


        }
    }
) */



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 10
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    dirLightGroup.rotation.y = elapsedTime
    ambientGroup.rotation.y = elapsedTime * 0.1

    // Animate 
    torus1.rotation.x = elapsedTime * parameters[0]
    torus1.rotation.y = elapsedTime * parameters[0]

    torus2.rotation.x = elapsedTime * parameters[1]
    torus2.rotation.y = elapsedTime * parameters[1]

    torus3.rotation.x = elapsedTime * parameters[2]
    torus3.rotation.y = elapsedTime * parameters[2] 


    torus4.rotation.x = elapsedTime * parameters[3]
    torus4.rotation.y = elapsedTime * parameters[3]

    torus5.rotation.x = elapsedTime * parameters[4]
    torus5.rotation.y = elapsedTime * parameters[4] 

    torus6.rotation.x = elapsedTime * parameters[5]
    torus6.rotation.y = elapsedTime * parameters[5]

    torus7.rotation.x = elapsedTime * parameters[6]
    torus7.rotation.y = elapsedTime * parameters[6] 


    torus8.rotation.x = elapsedTime * parameters[7]
    torus8.rotation.y = elapsedTime * parameters[7]

    torus9.rotation.x = elapsedTime * parameters[8]
    torus9.rotation.y = elapsedTime * parameters[8] 

    torus10.rotation.x = elapsedTime * parameters[9]
    torus10.rotation.y = elapsedTime * parameters[9]

    torus11.rotation.x = elapsedTime * parameters[10]
    torus11.rotation.y = elapsedTime * parameters[10] 


    //bigTorus1.rotation.x = elapsedTime * parameters[11]
    bigTorus1.rotation.y = elapsedTime * parameters[11] 

    bigTorus2.rotation.x = elapsedTime * parameters[12]
    //bigTorus2.rotation.y = elapsedTime * parameters[12] 

    bigTorus3.rotation.y = elapsedTime * parameters[10]

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()